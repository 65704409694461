import _ from 'lodash';
import axios from 'axios';
import {eventBus} from "./Shared/eventBus";
import {install} from 'resize-observer';

install();

window._ = _;

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
window.axios = axios;

window.axios.defaults.withCredentials = true;
window.axios.defaults.xsrfCookieName = window.location.hostname.includes('stage.')
    ? 'XSRF-TOKEN-STAGE'
    : 'XSRF-TOKEN';
window.axios.defaults.xsrfHeaderName = 'X-XSRF-TOKEN';

window.axios.defaults.validateStatus = function (status) {
    // Akzeptiere alle Statuscodes im Bereich von 200 bis 499
    return status >= 200 && status < 500;
};

window.axios.defaults.timeout = 15000;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.axios.interceptors.request.use(
    config => {
        eventBus.emit('hide-timeout-modal');
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

window.axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.code === 'ECONNABORTED') {
            eventBus.emit('show-timeout-modal');
        }
        return Promise.reject(error);
    }
);


/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.VITE_PUSHER_APP_KEY,
//     cluster: process.env.VITE_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
